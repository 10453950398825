@import '../../../libs/styles/responsive';

.home-intro-section {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: calc(100% - 60px);
    max-width: 1024px;
    margin: 60px auto 0;


    &::before {
        content: " ";
        position: absolute;
        left: calc(-10vw - 50px);
        top: -50px;
        width: 80vw;
        max-width: 700px;
        // outline: 1px solid pink;
        aspect-ratio: 2/3;
        z-index: -1;
        background: transparent url('/static/images/raphael-prader-portrait-on-black.jpg') 0 0 no-repeat;
        background-size: cover;
        opacity: 1;
        transition: opacity 0.25s ease;

        @media screen and (max-width: 425px) {
            width: 70vw;
            top: -60px;
            left: calc(-4vw - 50px);
        }
    }


    &.transition-initial-reset {
        &::before {
            opacity: 0;
        }

        .home-intro-section__content {
            transform: translateY(20px);
            opacity: 0;
        }
    }
}

.home-intro-section__content {
    width: 460px;
    max-width: 50%;
    transition: transform 0.75s ease, opacity 0.75s ease;
    opacity: 1;

    p {
        line-height: 1.4;

        @include target(lg, xxl) {
            font-size: 20px;
        }
    }

    .btn {
        margin-top: 20px;
    }

    @media screen and (max-width: 425px) {
        margin-top: 10px;

        p {
            padding-top: 5px;
            width: 200%;
            margin-top: 20px;
            margin-left: -100%;
            background-color: rgba(0, 0, 0, 0.6);
            backdrop-filter: blur(10px);
        }

        .btn {
            display: none;
            transform: translateX(-50%);
            width: 110%;
            text-align: center;
        }
    }

    @include target(md) {
        margin-top: 80px;
    }

    @include target(lg, xxl) {
        margin-top: 130px;
    }
}