@import 'libs/styles/responsive';
@import 'libs/styles/fonts';
@import 'app/styles/typography';

.technologies {
    width: 1024px;
    max-width: calc(100% - 60px);
    margin: 100px auto 0;

    h2 {
        font-size: 20px;
    }
}

.technologies__container {
    @include target(lg, xxl) {
        display: flex;
        flex-direction: row;
    }
}

.technology-list {
    margin-top: 20px;
    list-style: inside;

    &[title]::before {
        content: attr(title);
        display: block;
        font-weight: $fw-semi-bold;
        margin-bottom: 5px;
    }

    li {
        @extend p;
    }

    @include target(lg, xxl) {
        margin-right: 100px;
    }
}