@import 'libs/styles/responsive';


.philosophy {
    background: linear-gradient(129deg, #343434 0%, #222 100%);
    max-width: 1024px;
    margin: 40px auto 0;
    padding: 40px 30px;
    transition: transform 1s 0.5s ease, opacity 1s 0.5s ease;
    opacity: 1;

    h2 br {
        @include target(lg, xxl) {
            display: none;
        }
    }

    p {
        line-height: 1.6;
        margin-bottom: 1em;
    }

    @include target(md) {
        margin-top: 150px;
    }

    @include target(sm, xxl) {
        width: calc(100% - 60px);
    }

    @include target(xl, xxl) {
        margin-top: 200px;
        padding: 100px 130px 80px;
    }

    &.transition-initial-reset {
        opacity: 0;
        transform: translateY(20px);
    }
}