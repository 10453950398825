@import 'app/styles/fonts';
@import 'libs/styles/responsive';



*,
*::before,
*::after {
  @include nice-fonts;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  font-size: 100%;
  height: 100%;
}


body {
  min-height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  font-weight: $fw-light;
  font-style: normal;

  &.home {
    color: $color-white;
  }
}

h1 {
  display: inline-block;
  position: relative;
  font-size: 5.25vw;
  font-family: 'Poppins', sans-serif;
  font-weight: $fw-semi-bold;
  line-height: 1.2;
  letter-spacing: -0.01em;
  margin-bottom: 15px;
  padding-bottom: 10px;

  &::after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    background-color: $color-yellow;
  }

  @include target(sm) {
    font-size: 5vw;
  }

  @include target(md) {
    font-size: 26px;
  }

  @include target(lg, xxl) {
    font-size: 36px;
  }
}

h2 {
  @extend h1;
  padding-bottom: 10px;

  @include target(md) {
    font-size: 22px;
  }

  @include target(lg, xxl) {
    font-size: 32px;
  }
}

p {
  line-height: 1.5;
  font-size: 15px;

  @include target(md) {
    font-size: 16px;
  }

  @include target(lg, xxl) {
    font-size: 18px;
  }
}

b {
  font-weight: bold;
}