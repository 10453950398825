.target-groups-container {
    display: flex;
    gap: 30px;
    width: 1110px;
    margin: 50px auto;
    max-width: calc(100% - 40px);

    .target-group {
        flex-basis: 50%;
    }

    @media screen and (max-width: 800px) {
        margin: 20px auto;
        gap: 15px;
        flex-direction: column;
    }
}

.target-group {
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 10px;
    align-self: flex-start;

    @media screen and (min-width: 800px) {
        padding: 30px 40px;
    }

    h1 {
        font-size: 18px;

        @media screen and (min-width: 800px) {
            font-size: 24px;

            .mobile {
                display: none;
            }

            .span-for {
                text-transform: capitalize;
            }
        }
    }

    p {
        margin-bottom: 1em;
        font-size: 14px;

        @media screen and (min-width: 800px) {
            font-size: 16px;
        }
    }

    .btn {
        font-size: 16px;
        padding: 15px 20px;
    }

    &.target-group--minimized {
        position: relative;
        cursor: pointer;

        &::before {
            content: "+";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 20px;
            line-height: 42px;
            z-index: 1;
        }

        &::after {
            content: " ";
            position: absolute;
            width: 40px;
            height: 40px;
            bottom: 20px;
            left: 50%;
            border: 1px solid #d6c425;
            transform: translateX(-50%);
            transition: width 0.25s cubic-bezier(.4, .72, .29, .9);
            border-radius: 21px;
            background-color: #FFE600;
        }

        &:hover {
            &::before {
                font-size: 14px;
                content: "Mehr lesen";
            }

            &::after {
                width: 160px;
            }
        }
    }
}

.target-group__content {
    overflow: hidden;
    position: relative;
    transition: max-height 0.2s ease-in-out;
    max-height: 650px;

    .target-group--minimized & {
        max-height: 120px;

        &::before {
            position: absolute;
            content: " ";
            bottom: -30px;
            left: 0;
            right: 0;
            height: 90px;
            background: linear-gradient(0deg, rgba(247, 247, 247, 1) 32%, rgba(247, 247, 247, 0.55) 95%, rgba(247, 247, 247, 0.55) 100%);
        }
    }
}