@import '../../styles/config.scss';
@import '../../styles/fonts.scss';
@import 'libs/styles/responsive';

.btn {
    position: relative;
    display: inline-block;
    padding: 12px 24px;
    background: $color-yellow;
    font-family: 'Poppins', sans-serif;
    font-weight: $fw-semi-bold;
    text-decoration: none;
    color: #171717;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15), inset 0px -2px 4px rgba(187, 168, 0, 0.6);
    border-radius: 6px;
    transition: all 0.2s ease;

    @include target(lg, xxl) {
        font-size: 18px;
        padding: 15px 45px;
    }

    &:hover {
        transform: translateY(-2px);
        background: darken($color-yellow, 3%);
        box-shadow: 0px 10px 10px -5px rgba($color-yellow, 0.4), inset 0px -2px 4px rgba(187, 168, 0, 0.6);
    }

}