@import 'app/styles/config';
@import 'libs/styles/fonts';
@import 'libs/styles/responsive';

.contact {
    display: flex;
    flex-direction: row;
    width: 1024px;
    max-width: calc(100% - 60px);
    margin: 30px auto 0;
}

.contact__option {
    position: relative;
    display: inline-block;
    padding: 140px 20px 20px;
    margin-right: 15px;
    width: 50%;
    border-radius: 5px;
    background: linear-gradient(135deg, #866800 0%, #FFE600 100%);
    box-shadow: 0px 30px 30px -20px rgba(0, 0, 0, 0.29);
    color: $color-white;
    font-weight: $fw-semi-bold;
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    transform: translateY(0);
    transition: transform 0.2s ease;

    &::before {
        content: " ";
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        width: 70px;
        height: 70px;
        background-size: contain;
    }

    &::after {
        content: " ";
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        width: 60px;
        background-color: $color-white;
    }

    &:nth-child(2n) {
        background: linear-gradient(225deg, #866800 0%, #FFE600 100%);
    }

    &:last-child {
        margin-right: 0;
    }

    &:hover {
        transform: translateY(-2px);
    }

    @media screen and (max-width: 399px) {
        margin-right: 10px;

        &::after {
            bottom: 65px;
        }
    }

    @media screen and (min-width: 400px) {
        &::after {
            width: 100px;
        }

        br.mobile {
            display: none;
        }
    }

    @include target(lg, xxl) {
        font-size: 18px;
    }
}

.contact__option--email {
    &::before {
        background: url('/static/images/icon-mail.svg') center no-repeat;
    }
}

.contact__option--call {
    &::before {
        background: url('/static/images/icon-webcam.svg') center no-repeat;
    }
}