@import 'libs/styles/responsive';

.footer {
    padding: 25px 0;
    color: #ABABAB;
    font-size: 12px;

    p {
        text-align: center;
        font-size: inherit;
    }

    @include target(md, xxl) {
        margin-top: 80px;
        font-size: 13px;
    }
}

.footer-nav {
    margin-bottom: 15px;

    ul {
        display: flex;
        flex-direction: row;
        margin: auto;
        justify-content: center;

        li {
            padding: 0 5px;

            a {
                color: inherit;
                text-decoration: underline;
                text-underline-offset: 3px;
            }
        }
    }
}