/*********** Colors ***********/

$color-black: #000000;
$color-grey-dark: #313131;
$color-grey-darker: #212121;
$color-white: #ffffff;

$color-yellow: #FFE600;


/*************************************/

/*********** Breakpoints ***********/

$breakpoints: (
  // Breakpoint Definition
  xs: 0,
  // Extra small screen / phone
  sm: 576px,
  // Small screen / phone
  md: 768px,
  // Medium screen / tablet
  lg: 992px,
  // Large screen / desktop
  xl: 1024px,
  // Extra large screen / wide desktop
  xxl: 1300px,
  // Double extra large screen / wide desktop
);

/******************************/

/*************** Fonts ***************/

$font-OpenSans: 'OpenSans';
$font-SkModernist: 'Sk-Modernist';
$font-SuisseIntl: 'SuisseIntl';
$font-Tiempos: 'Tiempos';


// /*************************************/