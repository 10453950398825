@use 'libs/styles/responsive';
@import 'config';

body.home {
    background-color: $color-black;
}

body {
    padding-top: 115px;

    @include responsive.target(md, xxl) {
        padding-top: 85px;
    }
}

.nobr {
    white-space: nowrap;
}

.black-bg {
    background-color: black;
    padding-bottom: 50px;

    h1,
    h2,
    h3,
    ul,
    li,
    p {
        color: white;
    }

    &+.footer {
        margin-top: 0;
    }
}