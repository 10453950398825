@import 'libs/styles/responsive';
@import 'libs/styles/fonts';

.linkedin {
    width: 1024px;
    max-width: calc(100% - 60px);
    margin: 75px auto;

    p a {
        color: inherit;
        text-decoration: underline;
        text-underline-offset: 3px;
    }

    h2 {
        margin-bottom: 20px;

        @include target(md, xxl) {
            margin-bottom: 40px;
        }
    }
}

.linkedin__card {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    align-items: center;
}

.linkedin__card__image {
    flex-shrink: 0;
    width: 130px;
    height: 130px;
    margin-right: 15px;
    border-radius: 200px;
    background-color: #D9D9D9;

    @media screen and (max-width: 375px) {
        width: 110px;
        height: 110px;
    }
}

.linkedin__card__text {
    p {
        font-size: 13px;
    }

    a {
        display: inline-block;
        margin-top: 5px;
    }

    img {
        width: 65px;
    }
}

.linkedin__card__text__name {
    font-size: 16px !important;
    font-weight: $fw-semi-bold;
}