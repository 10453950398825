@import 'config';
@import 'libs/styles/fonts';
@import 'libs/styles/responsive';

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: $color-grey-dark;
    border-bottom: 1px solid $color-black;
    transition: border-bottom 0.25s ease;

    &::after {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(90deg, $color-grey-dark 0%, rgba(68, 68, 68, 1) 35%, $color-grey-dark 100%);
        z-index: -1;
        transition: opacity 0.25s linear;
        opacity: 0;
    }

    &.header--scrolled {
        border-bottom: 1px solid #616100;

        &::after {
            opacity: 1;
        }
    }
}

.header__logo {
    display: block;
    height: 48px;
    margin: 6px;

    &[src=""] {
        background-color: $color-yellow;
    }
}

.header__nav {
    position: relative;
    background-color: $color-grey-darker;

    &::before {
        content: " ";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.5);

        @include target(md, xxl) {
            display: none;
        }
    }
}

.header__nav-list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.header__nav-list__item {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: $fw-bold;
    color: $color-white;
    letter-spacing: 0.02em;

    &--active {
        text-decoration: underline;
        text-decoration-color: $color-yellow;
        text-underline-offset: 6px;
    }

    a {
        position: relative;
        display: block;
        color: inherit;
        text-decoration: none;
        padding: 18px 12px;
        transition: all 1s ease;

        &::after {
            content: " ";
            position: absolute;
            width: calc(100% - 24px);
            height: 1px;
            bottom: 14px;
            left: 12px;
            right: 12px;
            transform: scaleX(0);
            transform-origin: left;
            transition: all 1s ease;
            background-color: $color-yellow;
        }

        &:hover {
            color: $color-yellow;

            &::after {
                transform: scaleX(1);
            }
        }
    }
}

.header__nav-list__dot {
    $size: 6px;
    background-color: $color-white;
    height: $size;
    width: $size;
    border-radius: $size;
    transform: translateY(-1px);
}

@media screen and (min-width: 769px) {
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 30px;
    }

    .header__nav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: transparent;
        transform: translateY(3px);
    }

    .header__nav-list__item {
        font-size: 19px;
    }

    .header__nav-list__dot {
        margin: 0 30px;
    }

}