@import "libs/styles/responsive";

.references {
    margin-top: 40px;
    border-radius: 1px;
    background: linear-gradient(129deg, #343434 0%, #222 100%);

    @include target(md) {
        margin-top: 80px;
    }

    @include target(lg, xxl) {
        margin-top: 120px;
    }
}

.references__inner {
    width: 1024px;
    max-width: calc(100% - 60px);
    margin: 0 auto;
    padding: 40px 0;

    &>p {
        margin-top: 10px;
    }

    @include target(lg, xxl) {
        padding: 60px 0;

        &>p {
            margin-top: 30px;
        }
    }
}

.references-container {
    display: grid;
    margin-top: 20px;
    margin-bottom: 30px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;

    @include target(md, xxl) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(1, 1fr);
    }

    @include target(lg, xxl) {
        grid-column-gap: 30px;
    }
}

.reference {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 10px;
    border: 1px solid #686868;
    height: 90px;

    img {
        width: 135px;
        max-width: 75%;
    }

    &:first-child img {
        width: 150px;
    }

    @include target(lg, xxl) {
        height: 140px;
    }
}

.reference__disclaimer {
    position: absolute;
    color: #BDBDBD;
    font-size: 9px;
    text-align: center;
    top: calc(100% + 5px);
    margin: 0 -5px;
}