@import 'libs/styles/responsive';


.advantages {
    width: calc(100% - 60px);
    max-width: 1024px;
    margin: 120px auto 0;

    @include target(xs, sm) {
        margin-top: 40px;
    }

    @include target(md) {
        margin-top: 80px;
    }
}

.advantages__container {
    margin-top: 10px;

    @include target(lg, xxl) {
        display: grid;
        grid-template-areas: ". ." ". .";
        grid-gap: 30px;
        margin-top: 30px;
    }
}

.advantages__advantage {
    position: relative;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 15px 20px;
    background: #FBFBFB;
    color: #171717;

    h1 {
        font-size: 15px;
        padding-bottom: 0;
        padding: 0 40px 0 50px;
        margin-bottom: 0;
        line-height: 1.15;
        letter-spacing: -0.01em;
        width: 100%;

        &::before {
            position: absolute;
            left: -5px;
            top: 50%;
            transform: translateY(-50%);
            width: 38px;
            height: 38px;
            content: " ";
            border-radius: 20px;
        }

        &::after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: auto;
            right: 0;
            width: 18px;
            height: 18px;
            content: " ";
            background: transparent url('/static/images/arrow-right.svg') center no-repeat;
            background-size: contain;
        }
    }

    p {
        display: none;
        font-size: 14px;
        line-height: 1.4;
        margin-top: 20px;

        @include target(lg, xxl) {
            font-size: 15px;
        }
    }

    &.advantages__advantage--open {
        h1::after {
            transform: translateY(-50%) rotate(90deg);
        }

        p {
            display: block;
        }

        @include target(xs, md) {
            &::before {
                top: 10px;
                transform: none;
            }
        }
    }



    &--efficiency {
        h1::before {
            background: #FFE0E0 url('/static/images/emoji-efficiency.png') center 3px no-repeat;
            background-size: 24px;
        }
    }

    &--team {
        h1::before {
            background: #E8FFE0 url('/static/images/emoji-flexible.png') center 8px no-repeat;
            background-size: 23px;
        }
    }

    &--solution {
        h1::before {
            background: #FFF5D3 url('/static/images/emoji-solutions.png') center 6px no-repeat;
            background-size: 24px;
        }
    }

    &--language {
        h1::before {
            background: #F3F3F3 url('/static/images/emoji-communication.png') center 7px no-repeat;
            background-size: 24px;
        }
    }


    @include target(lg, xxl) {
        padding: 30px 40px;
        margin-bottom: 0;

        &::before {
            left: 28px;
            top: 22px;
        }

        h1 {
            min-height: 46px;
            font-size: 20px;
            padding: 0 0 0 50px;
            margin-right: -10px;

            &::after {
                display: none;
            }
        }

        p {
            display: block !important;
            margin-top: 10px;
        }
    }
}

@include target(lg, xxl) {
    .advantages__advantage__headline-container {
        display: flex;
        align-items: center;
        min-height: 46px;

        h1 {
            min-height: auto;
        }
    }
}